.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  -webkit-transition: all 0.25s ease-in;
  -moz-transition: all 0.25s ease-in;
  -ms-transition: all 0.25s ease-in;
  -o-transition: all 0.25s ease-in;
  transition: all 0.25s ease-in;
  opacity: 0.4;
  min-width: 80px;
  width: auto;
  position: absolute;
  z-index: 2;
  /* top: 20px; */
  background: 0 0;
  border: 0;
  font-size: 32px;
  cursor: pointer;
}
.carousel .control-arrow:hover {
  opacity: 1;
}
.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
  margin: 0 5px;
  display: inline-block;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  content: '';
}
.carousel .control-disabled.control-arrow {
  opacity: 0;
  cursor: inherit;
  display: none;
}
.carousel .control-prev.control-arrow {
  left: 0;
}
.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #fff;
}
.carousel .control-next.control-arrow {
  right: 0;
}
.carousel .control-next.control-arrow:before {
  border-left: 8px solid #fff;
}
.carousel .control-dots {
  position: relative;
  bottom: 0;
  margin: 10px 0;
  text-align: center;
  width: 100%;
  margin-bottom: -2px;
}
@media (min-width: 960px) {
  .carousel .control-dots {
    bottom: 0;
  }
}
.carousel .control-dots .dot {
  -webkit-transition: opacity 0.25s ease-in;
  -moz-transition: opacity 0.25s ease-in;
  -ms-transition: opacity 0.25s ease-in;
  -o-transition: opacity 0.25s ease-in;
  transition: opacity 0.25s ease-in;
  border: 0px;
  outline: 0px;
  opacity: 0.3;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
  background: white;
  border-radius: 50%;
  width: 13px;
  height: 13px;
  cursor: pointer;
  display: inline-block;
  margin: 0 8px;
}
.carousel .control-dots .dot.selected {
  background: #ffc20a;
}
.carousel .control-dots .dot:hover {
  opacity: 1;
}
