body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
@font-face {
  font-family: 'Avenir';
  src: url('fonts/AvenirLTStd-Roman.otf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Noir';
  src: url('fonts/NoirPro-Bold.woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Noir-SemiBold';
  src: url('fonts/NoirPro-SemiBold.woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
  text-align: center;
}
